.bigblue {
    color: DodgerBlue;
    padding: 40px;
    font-family: Arial;
    text-align: center;
}

.noScroll {
    overflow-Y: "hidden";
    overflow-X: "hidden";
  }

